import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonAcheterTirageAvecPrix from "../../../../composants/boutique/BoutonAcheterTirageAvecPrix"
import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../../composants/image/Panellum"

import {
  obtenirPage,
  obtenirImage,
  obtenirImages,
} from "../../../../js/client-es/utils"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "yeuxDuSalar360EN" } }
    ) {
      ...FragmentPageYaml
    }
    imageAVendre: allImagesCompilationYaml(
      filter: { code: { in: ["voieLacteeSourceUnivers"] } }
    ) {
      nodes {
        ...FragmentDonneesImages
      }
    }
  }
`

export default function Teide360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")
  const images = obtenirImages(resultatsRequete, "imageAVendre")

  const composantImageInteractive = (
    <Pannellum urlImageSource="/ressources/360/yeuxDuSalar.jpg" />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          In the early morning around 6.30am, the Milky Way was reflecting in a
          small perfectly calm waterhole in Chile’s Atacama Desert. In a
          complete darkness, only the light emitted by the most beautiful starry
          sky of the world allowed us to find our way. The atmosphere was magic.
          An Incredible calm was reigning, just disturbed by a few distant dogs
          barking. Highlight of the show, behind us, a very intense zodiacal
          light was illuminating the Andes…
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          Panoramic of 29 images, panoramic head Nodal Ninja 3, Canon EOS 6D
          Astrodon modded, Samyang 24mm F1.4 set to F2.2, ISO 3200. Individual
          exposures of 20 seconds.
        </div>
        <div id="galerie-image-interactive-conteneur-achat-tirage-avec-prix">
          {" "}
          <BoutonAcheterTirageAvecPrix
            image={obtenirImage(images, "voieLacteeSourceUnivers")}
            langue={page.langue}
          />
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
